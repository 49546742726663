(function (w, $) {
    'use strict';
    
    // 检查是否为IE8或更低浏览器
    var isOlderIE = false;
    if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
        var ieversion = new Number(RegExp.$1);
        if (ieversion <= 8) {
            isOlderIE = true;
        }
        
        if (ieversion == 8) {
            $("html").addClass('ie8');
        }
    }


    var isAsideAttached = false,
        atBottom = false;
    var checkAside = function () {
        if ($(window).scrollTop() > 80) {
            if (!isAsideAttached) {
                $("#asided-page").addClass('attached');
                isAsideAttached = true;
            }


            if ($(document).height() - $(window).scrollTop() - $('footer').outerHeight() - 10 < $(".side-box").height()) {
                if (!atBottom) {
                    $("#asided-page").addClass('bottom');
                    atBottom = true;
                }

            } else {
                if (atBottom) {
                    $("#asided-page").removeClass('bottom');
                    atBottom = false;
                }
            }

        } else {

            if (isAsideAttached) {
                $("#asided-page").removeClass('attached');
                isAsideAttached = false;
            }

            if (atBottom) {
                $("#asided-page").removeClass('bottom');
                atBottom = false;
            }
        }
    }

    /**
     * 识别并转换两种模式的图片
     * 一种是单独一个<img>标签
     * 另一种为<p><img /><a>xxx</a></p>
     * 将<a></a>作为附件的形式下载
     */
    var initLightBox = function () {
        if (isOlderIE) {
            $(".content-area img")
                .css('cursor', 'pointer')
                .click(function(){
                    $(this).toggleClass('expand');
                })
                .parent('p').find('a').remove();
            return;
        }
        
        $(".content-area img").each(function (idx) {
            var $parentParagragh = $(this).parent('p'),
                downloadLinks = [];

            // 第二种模式
            if ($parentParagragh) {
                $parentParagragh.find('a').not(".fs-lightbox-element").each(function () {
                    downloadLinks.push({
                        title: $(this).html(),
                        href: $(this).attr('href')
                    });

                    $(this).remove();
                });
            }

            $(this).wrap(function () {
                return '<a data-lightbox-gallery="photo_gallery" href="' + $(this).attr('src') + '"></a>';
            })
            .parent()
            .data('downloadLinks', downloadLinks)
            .lightbox({
                theme: "fs-light",
                fixed: true,
                margin: 10,
                labels: {
                    captionClosed: "查看附件",
                    captionOpen: "关闭附件"
                },
                formatter: function () {
                    var html = '',
                        _data = $(this).data('downloadLinks');
//                    console.log(_data);
                    
                    $.each(_data, function (idx, row) {
                        html += '<a href="' + row.href + '" class="attached-item"><i class="icon icon-attachment"></i> ' + row.title + '</a>';
                    });
                    return html;
                }
            });
        });
        
        
        // wheelzoom the img
        $(window).on("open.lightbox", function() {
            var $target = $(".fs-lightbox")
                .not(".fs-lightbox-mobile")
                .find(".fs-lightbox-image");
            
            wheelzoom( $target.css('cursor', 'ns-resize') );
        });
    }

    var initMinHeight = function () {
        var newMargin = $(window).height() - $('footer').offset().top - $('footer').outerHeight();

        if (newMargin > 0) {
            $('footer').css('marginTop', newMargin);
        }
    }
    
    var scrollToTitle = function(e) {
        e.preventDefault();
        
        var targetId = $(this).attr('href');
        
        if (targetId) {
            $('html, body').stop().animate({
                scrollTop: $(targetId).offset().top
            }, 1000);
        }
    }


    $(function () {
        checkAside();
        $('.nav-expand').click(function () {
            $(this).parent().toggleClass('open');
        });
        $(window).scroll(checkAside);
        
        $('.toc-links').click(scrollToTitle);

        initLightBox();
        initMinHeight();
    });

})(this, jQuery);